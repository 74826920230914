import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import PagePadding from '../components/PagePadding'
import PageData from '../types/PageData'

type Props = {
  data: {
    sanityPage: PageData
  }
}

const GiftcardsPage: React.FC<Props> = ({ data }) => {
  useEffect(() => {
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: 'cody-calligraphy.myshopify.com',
        storefrontAccessToken: 'a0bd6b8ca631575b7162630fe915fd79',
      })
      ShopifyBuy.UI.onReady(client).then(function(ui) {
        ui.createComponent('product', {
          id: '4508452978742',
          node: document.getElementById('product-component-1586357646869'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
            product: {
              styles: {
                product: {
                  '@media (min-width: 601px)': {
                    'max-width': '100%',
                    'margin-left': '0',
                    'margin-bottom': '50px',
                  },
                  'text-align': 'left',
                },
                title: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'normal',
                  'font-size': '26px',
                  color: '#2a2a2a',
                },
                button: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'bold',
                  ':hover': {
                    'background-color': '#8a4132',
                  },
                  'background-color': '#994837',
                  ':focus': {
                    'background-color': '#8a4132',
                  },
                  'border-radius': '15px',
                },
                price: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '18px',
                  color: '#2a2a2a',
                },
                compareAt: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '15.299999999999999px',
                  color: '#2a2a2a',
                },
                unitPrice: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '15.299999999999999px',
                  color: '#2a2a2a',
                },
                description: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  color: '#2a2a2a',
                },
              },
              buttonDestination: 'checkout',
              layout: 'horizontal',
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
                description: true,
              },
              width: '100%',
              text: {
                button: 'Buy now',
              },
              googleFonts: ['Montserrat'],
            },
            productSet: {
              styles: {
                products: {
                  '@media (min-width: 601px)': {
                    'margin-left': '-20px',
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
              },
              styles: {
                product: {
                  '@media (min-width: 601px)': {
                    'max-width': '100%',
                    'margin-left': '0px',
                    'margin-bottom': '0px',
                  },
                },
                button: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'bold',
                  ':hover': {
                    'background-color': '#8a4132',
                  },
                  'background-color': '#994837',
                  ':focus': {
                    'background-color': '#8a4132',
                  },
                  'border-radius': '15px',
                },
                title: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'normal',
                },
                price: {
                  'font-family': 'Raleway, sans-serif',
                },
                compareAt: {
                  'font-family': 'Raleway, sans-serif',
                },
                unitPrice: {
                  'font-family': 'Raleway, sans-serif',
                },
              },
              googleFonts: ['Montserrat', 'Raleway'],
              text: {
                button: 'Add to Cart',
              },
            },
            cart: {
              styles: {
                button: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'bold',
                  ':hover': {
                    'background-color': '#8a4132',
                  },
                  'background-color': '#994837',
                  ':focus': {
                    'background-color': '#8a4132',
                  },
                  'border-radius': '15px',
                },
                title: {
                  color: '#2a2a2a',
                },
                header: {
                  color: '#2a2a2a',
                },
                lineItems: {
                  color: '#2a2a2a',
                },
                subtotalText: {
                  color: '#2a2a2a',
                },
                subtotal: {
                  color: '#2a2a2a',
                },
                notice: {
                  color: '#2a2a2a',
                },
                currency: {
                  color: '#2a2a2a',
                },
                close: {
                  color: '#2a2a2a',
                  ':hover': {
                    color: '#2a2a2a',
                  },
                },
                empty: {
                  color: '#2a2a2a',
                },
                noteDescription: {
                  color: '#2a2a2a',
                },
                discountText: {
                  color: '#2a2a2a',
                },
                discountIcon: {
                  fill: '#2a2a2a',
                },
                discountAmount: {
                  color: '#2a2a2a',
                },
              },
              text: {
                total: 'Total',
                notice: 'You will receive your PDF download after purchasing.',
                button: 'Checkout',
              },
              popup: false,
              googleFonts: ['Montserrat'],
            },
            toggle: {
              styles: {
                toggle: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'bold',
                  'background-color': '#994837',
                  ':hover': {
                    'background-color': '#8a4132',
                  },
                  ':focus': {
                    'background-color': '#8a4132',
                  },
                },
              },
              googleFonts: ['Montserrat'],
            },
            lineItem: {
              styles: {
                variantTitle: {
                  color: '#2a2a2a',
                },
                title: {
                  color: '#2a2a2a',
                },
                price: {
                  color: '#2a2a2a',
                },
                fullPrice: {
                  color: '#2a2a2a',
                },
                discount: {
                  color: '#2a2a2a',
                },
                discountIcon: {
                  fill: '#2a2a2a',
                },
                quantity: {
                  color: '#2a2a2a',
                },
                quantityIncrement: {
                  color: '#2a2a2a',
                  'border-color': '#2a2a2a',
                },
                quantityDecrement: {
                  color: '#2a2a2a',
                  'border-color': '#2a2a2a',
                },
                quantityInput: {
                  color: '#2a2a2a',
                  'border-color': '#2a2a2a',
                },
              },
            },
          },
        })
      })
    }
    ShopifyBuyInit()
  }, [])

  return (
    <Layout pageData={data?.sanityPage} pageTitle="Gift Cards">
      <Seo
        title="Gift Cards"
        description="Purchase a giftcard for wedding invitation, calligraphy or day-of stationery services."
      />
      <Container>
        <section id="product-component-1586357646869"></section>
      </Container>
    </Layout>
  )
}

export default GiftcardsPage

export const query = graphql`
  query GiftcardsPageQuery {
    sanityPage(uuid: { eq: "giftcards" }) {
      _rawPageMessage
    }
  }
`

const Container = styled(PagePadding)`
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 400px;
`
